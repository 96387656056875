import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const app = initializeApp({
  apiKey: 'AIzaSyDBI9x0xvXgQpimGJdtJNwQ0pcFZQelVhc',
  authDomain: 'openai-1111.firebaseapp.com',
  projectId: 'openai-1111',
  storageBucket: 'openai-1111.appspot.com',
  messagingSenderId: '161322896136',
  appId: '1:161322896136:web:139fc313c5231aa63ebabd',
  measurementId: 'G-M9ZNJX9GMY'
});

const functions = getFunctions(app);

const getResponse = async prompt => {
  const personaResponse = httpsCallable(functions, 'textCompletion');
  const response = await personaResponse({ prompt });
  return response;
};

export { getResponse };
